@import '../variables';
$navy-brand: #ff8800;
$navy-secondary: #6a55c0;
$navy-success: #80d217;
$navy-warning: #ffce2e;
$navy-danger: #ff3358;
$navy-info: #7fafa8;

$navy-light-gray: rgb(229, 228, 238);
$navy-gray: #9f9eae;
$navy-dark-gray: #453e6f;

$navy-brand-d4: mix($navy-brand, black, 40%);
$navy-brand-d3: mix($navy-brand, black, 55%);
$navy-brand-d2: mix($navy-brand, black, 70%);
$navy-brand-d1: mix($navy-brand, black, 83%);
$navy-brand-l4: mix($navy-brand, white, 80%);
$navy-brand-l3: mix($navy-brand, white, 55%);
$navy-brand-l2: mix($navy-brand, white, 30%);
$navy-brand-l1: mix($navy-brand, white, 10%);

$navy-secondary-d4: #282843;
$navy-secondary-d3: #373062;
$navy-secondary-d2: #4d4386;
$navy-secondary-d1: #7169a6;
$navy-secondary-l4: #958fbd;
$navy-secondary-l3: #c5c1d9;
$navy-secondary-l2: #dbd6e7;
$navy-secondary-l1: #eceaf4;

$navy-success-d4: mix($navy-success, black, 40%);
$navy-success-d3: mix($navy-success, black, 55%);
$navy-success-d2: mix($navy-success, black, 70%);
$navy-success-d1: mix($navy-success, black, 83%);
$navy-success-l4: mix($navy-success, white, 82%);
$navy-success-l3: mix($navy-success, white, 60%);
$navy-success-l2: mix($navy-success, white, 35%);
$navy-success-l1: mix($navy-success, white, 18%);

$navy-warning-d4: mix($navy-warning, black, 40%);
$navy-warning-d3: mix($navy-warning, black, 58%);
$navy-warning-d2: mix($navy-warning, black, 75%);
$navy-warning-d1: mix($navy-warning, black, 90%);
$navy-warning-l4: mix($navy-warning, white, 75%);
$navy-warning-l3: mix($navy-warning, white, 55%);
$navy-warning-l2: mix($navy-warning, white, 35%);
$navy-warning-l1: mix($navy-warning, white, 20%);

$navy-danger-d4: mix($navy-danger, black, 40%);
$navy-danger-d3: mix($navy-danger, black, 55%);
$navy-danger-d2: mix($navy-danger, black, 70%);
$navy-danger-d1: mix($navy-danger, black, 83%);
$navy-danger-l4: mix($navy-danger, white, 75%);
$navy-danger-l3: mix($navy-danger, white, 50%);
$navy-danger-l2: mix($navy-danger, white, 30%);
$navy-danger-l1: mix($navy-danger, white, 10%);

$navy-info-d4: mix($navy-info, black, 40%);
$navy-info-d3: mix($navy-info, black, 55%);
$navy-info-d2: mix($navy-info, black, 70%);
$navy-info-d1: mix($navy-info, black, 83%);
$navy-info-l4: mix($navy-info, white, 75%);
$navy-info-l3: mix($navy-info, white, 50%);
$navy-info-l2: mix($navy-info, white, 30%);
$navy-info-l1: mix($navy-info, white, 10%);

$navy-gray-d: mix($navy-gray, black, 50%);
$navy-gray-l: mix($navy-gray, white, 30%);
$navy-light-gray-d: mix($navy-light-gray, black, 50%);
$navy-light-gray-l: mix($navy-light-gray, white, 30%);
$navy-dark-gray-d: mix($navy-dark-gray, black, 50%);
$navy-dark-gray-l: mix($navy-dark-gray, white, 30%);

$navy-depth-base: white;
$navy-depth-1: mix($navy-depth-base, #e4e2ec, 95%);
$navy-depth-2: mix($navy-depth-base, #e4e2ec, 85%);
$navy-depth-3: mix($navy-depth-base, #e4e2ec, 75%);
$navy-depth-4: mix($navy-depth-base, #e4e2ec, 65%);
$navy-depth-5: mix($navy-depth-base, #e4e2ec, 55%);
$navy-depth-6: mix($navy-depth-base, #e4e2ec, 45%);
$navy-depth-7: mix($navy-depth-base, #e4e2ec, 30%);
$navy-depth-8: mix($navy-depth-base, #e4e2ec, 15%);
$navy-depth-9: mix(#1f1f27, #e4e2ec, 10%);
$navy-depth-10: mix(#1f1f27, #e4e2ec, 50%);

$dark-brand: #e5891f;
$dark-secondary: #9482ec;
$dark-success: #81be30;
$dark-warning: #e8bb28;
$dark-danger: #f14665;
$dark-info: #5ab1a3;

$dark-light-gray: rgba(225, 223, 240, 0.1);
$dark-gray: rgba(225, 223, 240, 0.25);
$dark-dark-gray: rgba(225, 223, 240, 0.4);

$dark-dark-brand-d4: mix($dark-brand, black, 40%);
$dark-brand-d3: mix($dark-brand, black, 55%);
$dark-brand-d2: mix($dark-brand, black, 70%);
$dark-brand-d1: mix($dark-brand, black, 83%);
$dark-brand-l4: mix($dark-brand, white, 80%);
$dark-brand-l3: mix($dark-brand, white, 55%);
$dark-brand-l2: mix($dark-brand, white, 30%);
$dark-brand-l1: mix($dark-brand, white, 10%);

$dark-secondary-d4: mix($dark-secondary, black, 40%);
$dark-secondary-d3: mix($dark-secondary, black, 55%);
$dark-secondary-d2: mix($dark-secondary, black, 70%);
$dark-secondary-d1: mix($dark-secondary, black, 83%);
$dark-secondary-l4: mix($dark-secondary, white, 80%);
$dark-secondary-l3: mix($dark-secondary, white, 55%);
$dark-secondary-l2: mix($dark-secondary, white, 30%);
$dark-secondary-l1: mix($dark-secondary, white, 10%);

$dark-success-d4: mix($dark-success, black, 40%);
$dark-success-d3: mix($dark-success, black, 55%);
$dark-success-d2: mix($dark-success, black, 70%);
$dark-success-d1: mix($dark-success, black, 83%);
$dark-success-l4: mix($dark-success, white, 82%);
$dark-success-l3: mix($dark-success, white, 60%);
$dark-success-l2: mix($dark-success, white, 35%);
$dark-success-l1: mix($dark-success, white, 18%);

$dark-warning-d4: mix($dark-warning, black, 40%);
$dark-warning-d3: mix($dark-warning, black, 58%);
$dark-warning-d2: mix($dark-warning, black, 75%);
$dark-warning-d1: mix($dark-warning, black, 90%);
$dark-warning-l4: mix($dark-warning, white, 75%);
$dark-warning-l3: mix($dark-warning, white, 55%);
$dark-warning-l2: mix($dark-warning, white, 35%);
$dark-warning-l1: mix($dark-warning, white, 20%);

$dark-danger-d4: mix($dark-danger, black, 40%);
$dark-danger-d3: mix($dark-danger, black, 55%);
$dark-danger-d2: mix($dark-danger, black, 70%);
$dark-danger-d1: mix($dark-danger, black, 83%);
$dark-danger-l4: mix($dark-danger, white, 75%);
$dark-danger-l3: mix($dark-danger, white, 50%);
$dark-danger-l2: mix($dark-danger, white, 30%);
$dark-danger-l1: mix($dark-danger, white, 10%);

$dark-info-d4: mix($dark-info, black, 40%);
$dark-info-d3: mix($dark-info, black, 55%);
$dark-info-d2: mix($dark-info, black, 70%);
$dark-info-d1: mix($dark-info, black, 83%);
$dark-info-l4: mix($dark-info, white, 75%);
$dark-info-l3: mix($dark-info, white, 50%);
$dark-info-l2: mix($dark-info, white, 30%);
$dark-info-l1: mix($dark-info, white, 10%);

$dark-gray-d: mix($dark-gray, black, 30%);
$dark-gray-l: mix($dark-gray, white, 30%);
$dark-light-gray-d: mix($dark-light-gray, black, 30%);
$dark-light-gray-l: mix($dark-light-gray, white, 30%);
$dark-dark-gray-d: mix($dark-dark-gray, black, 30%);
$dark-dark-gray-l: mix($dark-dark-gray, white, 30%);

$dark-depth-base: #1f1f27;
$dark-depth-1: mix($dark-depth-base, #ffffff, 98%);
$dark-depth-2: mix($dark-depth-base, #ffffff, 95%);
$dark-depth-3: mix($dark-depth-base, #ffffff, 92%);
$dark-depth-4: mix($dark-depth-base, #ffffff, 89%);
$dark-depth-5: mix($dark-depth-base, #ffffff, 87%);
$dark-depth-6: mix($dark-depth-base, #ffffff, 85%);
$dark-depth-7: mix($dark-depth-base, #ffffff, 83%);
$dark-depth-8: mix($dark-depth-base, #ffffff, 80%);
$dark-depth-9: mix($dark-depth-base, #ffffff, 70%);
$dark-depth-10: mix($dark-depth-base, #ffffff, 20%);

$navy-base-txt: $f-b-3;
$dark-base-txt: $f-w-2;

//공용
$gray-100: #e7e7eb;
$gray-200: #cfcfd7;
$gray-300: #b7b6c2;
$gray-400: #9f9eae; //disable-bg
$gray-500: #87869a; //form-border
$gray-600: #6e6e86;
$gray-700: #565672;
$gray-800: #3e3d5d;
$gray-900: #262549;

html[color-theme='pnt-navy'] {
  --base-txt: #{$navy-base-txt};
  --brand: #{$navy-brand};
  --secondary: #{$navy-secondary};
  --success: #{$navy-success};
  --warning: #{$navy-warning};
  --danger: #{$navy-danger};
  --info: #{$navy-info};

  --light-gray: #{$navy-light-gray};
  --gray: #{$navy-gray};
  --dark-gray: #{$navy-dark-gray};

  --brand-d4: #{$navy-brand-d4};
  --brand-d3: #{$navy-brand-d3};
  --brand-d2: #{$navy-brand-d2};
  --brand-d1: #{$navy-brand-d1};
  --brand-l4: #{$navy-brand-l4};
  --brand-l3: #{$navy-brand-l3};
  --brand-l2: #{$navy-brand-l2};
  --brand-l1: #{$navy-brand-l1};

  --secondary-d4: #{$navy-secondary-d4};
  --secondary-d3: #{$navy-secondary-d3};
  --secondary-d2: #{$navy-secondary-d2};
  --secondary-d1: #{$navy-secondary-d1};
  --secondary-l4: #{$navy-secondary-l4};
  --secondary-l3: #{$navy-secondary-l3};
  --secondary-l2: #{$navy-secondary-l2};
  --secondary-l1: #{$navy-secondary-l1};

  --success-d4: #{$navy-success-d4};
  --success-d3: #{$navy-success-d3};
  --success-d2: #{$navy-success-d2};
  --success-d1: #{$navy-success-d1};
  --success-l4: #{$navy-success-l4};
  --success-l3: #{$navy-success-l3};
  --success-l2: #{$navy-success-l2};
  --success-l1: #{$navy-success-l1};

  --warning-d4: #{$navy-warning-d4};
  --warning-d3: #{$navy-warning-d3};
  --warning-d2: #{$navy-warning-d2};
  --warning-d1: #{$navy-warning-d1};
  --warning-l4: #{$navy-warning-l4};
  --warning-l3: #{$navy-warning};
  --warning-l2: #{$navy-warning-l2};
  --warning-l1: #{$navy-warning-l1};

  --danger-d4: #{$navy-danger-d4};
  --danger-d3: #{$navy-danger-d3};
  --danger-d2: #{$navy-danger-d2};
  --danger-d1: #{$navy-danger-d1};
  --danger-l4: #{$navy-danger-l4};
  --danger-l3: #{$navy-danger-l3};
  --danger-l2: #{$navy-danger-l2};
  --danger-l1: #{$navy-danger-l1};

  --info-d4: #{$navy-info-d4};
  --info-d3: #{$navy-info-d3};
  --info-d2: #{$navy-info-d2};
  --info-d1: #{$navy-info-d1};
  --info-l4: #{$navy-info-l4};
  --info-l3: #{$navy-info-l3};
  --info-l2: #{$navy-info-l2};
  --info-l1: #{$navy-info-l1};

  --gray-d: #{$navy-gray-d};
  --gray-l: #{$navy-gray-l};
  --light-gray-d: #{$navy-light-gray-d};
  --light-gray-l: #{$navy-light-gray-l};
  --dark-gray-d: #{$navy-dark-gray-d};
  --dark-gray-l: #{$navy-dark-gray-l};

  --depth-base: #{$navy-depth-base};
  --depth-1: #{$navy-depth-1};
  --depth-2: #{$navy-depth-2};
  --depth-3: #{$navy-depth-3};
  --depth-4: #{$navy-depth-4};
  --depth-5: #{$navy-depth-5};
  --depth-6: #{$navy-depth-6};
  --depth-7: #{$navy-depth-7};
  --depth-8: #{$navy-depth-8};
  --depth-9: #{$navy-depth-9};
  --depth-10: #{$navy-depth-10};

  --form-active-border: #{$gray-700};
  --form-border: #{$navy-depth-8};
  --form-bg: #{$navy-depth-4};
  --form-disable-bg: #{$navy-depth-8};
  --table-thead-bg: #{$navy-depth-4};
  --form-hover-bg: #{$gray-200};
}

html[color-theme='pnt-dark'] {
  --base-txt: #{$dark-base-txt};
  --brand: #{$dark-brand};
  --secondary: #{$dark-secondary};
  --success: #{$dark-success};
  --warning: #{$dark-warning};
  --danger: #{$dark-danger};
  --info: #{$dark-info};

  --light-gray: #{$dark-light-gray};
  --gray: #{$dark-gray};
  --dark-gray: #{$dark-dark-gray};

  --brand-d4: #{$dark-brand-d3};
  --brand-d3: #{$dark-brand-d3};
  --brand-d2: #{$dark-brand-d2};
  --brand-d1: #{$dark-brand-d1};
  --brand-l4: #{$dark-brand-l4};
  --brand-l3: #{$dark-brand-l3};
  --brand-l2: #{$dark-brand-l2};
  --brand-l1: #{$dark-brand-l1};

  --secondary-d4: #{$dark-secondary-d4};
  --secondary-d3: #{$dark-secondary-d3};
  --secondary-d2: #{$dark-secondary-d2};
  --secondary-d1: #{$dark-secondary-d1};
  --secondary-l4: #{$dark-secondary-l4};
  --secondary-l3: #{$dark-secondary-l3};
  --secondary-l2: #{$dark-secondary-l2};
  --secondary-l1: #{$dark-secondary-l1};

  --success-d4: #{$dark-success-d4};
  --success-d3: #{$dark-success-d3};
  --success-d2: #{$dark-success-d2};
  --success-d1: #{$dark-success-d1};
  --success-l4: #{$dark-success-l4};
  --success-l3: #{$dark-success-l3};
  --success-l2: #{$dark-success-l2};
  --success-l1: #{$dark-success-l1};

  --warning-d4: #{$dark-warning-d4};
  --warning-d3: #{$dark-warning-d3};
  --warning-d2: #{$dark-warning-d2};
  --warning-d1: #{$dark-warning-d1};
  --warning-l4: #{$dark-warning-l4};
  --warning-l3: #{$dark-warning};
  --warning-l2: #{$dark-warning-l2};
  --warning-l1: #{$dark-warning-l1};

  --danger-d4: #{$dark-danger-d4};
  --danger-d3: #{$dark-danger-d3};
  --danger-d2: #{$dark-danger-d2};
  --danger-d1: #{$dark-danger-d1};
  --danger-l4: #{$dark-danger-l4};
  --danger-l3: #{$dark-danger-l3};
  --danger-l2: #{$dark-danger-l2};
  --danger-l1: #{$dark-danger-l1};

  --info-d4: #{$dark-info-d4};
  --info-d3: #{$dark-info-d3};
  --info-d2: #{$dark-info-d2};
  --info-d1: #{$dark-info-d1};
  --info-l4: #{$dark-info-l4};
  --info-l3: #{$dark-info-l3};
  --info-l2: #{$dark-info-l2};
  --info-l1: #{$dark-info-l1};

  --gray-d: #{$dark-gray-d};
  --gray-l: #{$dark-gray-l};
  --light-gray-d: #{$dark-light-gray-d};
  --light-gray-l: #{$dark-light-gray-l};
  --dark-gray-d: #{$dark-dark-gray-d};
  --dark-gray-l: #{$dark-dark-gray-l};

  --depth-base: #{$dark-depth-base};
  --depth-1: #{$dark-depth-1};
  --depth-2: #{$dark-depth-2};
  --depth-3: #{$dark-depth-3};
  --depth-4: #{$dark-depth-4};
  --depth-5: #{$dark-depth-5};
  --depth-6: #{$dark-depth-6};
  --depth-7: #{$dark-depth-7};
  --depth-8: #{$dark-depth-8};
  --depth-9: #{$dark-depth-9};
  --depth-10: #{$dark-depth-10};

  --form-active-border: #{$gray-600};
  --form-border: #{$dark-depth-6};
  --form-bg: #{$dark-depth-7};
  --form-disable-bg: #{$dark-depth-3};
  --table-thead-bg: #{$dark-depth-2};
  --form-hover-bg: #{$dark-depth-9};
}
