$out-of-range-color: lighten(#333, 50%);

$calendar-border: $gray-200;
$cell-border: $gray-200;

$border-color: $gray-300;

$segment-width: percentage(1 / 7);

$time-selection-color: #white;
$time-selection-bg-color: rgba(0, 0, 0, 0.5);
$date-selection-bg-color: rgba(0, 0, 0, 0.1);

$event-bg: $primary;
$event-border: darken($event-bg, 10%);
$event-color: $white;
$event-border-radius: $border-radius;
$event-padding: $dropdown-spacer;
$event-zindex: 4;

$btn-color: $white;
$btn-bg: $primary;
$btn-border: $primary;

$current-time-color: $success;

$rbc-css-prefix: rbc-i;

$today-highlight-bg: $dropdown-link-hover-bg;
