/// switch

.has-switch {
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  line-height: 1.72222;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 60px;
  user-select: none;
}
.has-switch.deactivate {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}
.has-switch.deactivate label,
.has-switch.deactivate span {
  cursor: default !important;
}
.has-switch > div {
  position: relative;
  top: 0;
  width: 100px;
}
.has-switch > div.switch-animate {
  transition: left 0.25s ease-out;
}
.has-switch > div.switch-off {
  left: -35px;
}

.has-switch > div.switch-on {
  left: 0;
}

@mixin icon-gradient($top-color, $bottom-color) {
  background: $top-color;
  background: linear-gradient(to bottom, $top-color 0%, $bottom-color 100%);
  background-size: 150% 150%;
}

.has-switch > div label {
  background-color: #ffffff;
  @include icon-gradient(rgba(255, 255, 255, 1), rgba(241, 241, 242, 1));

  box-shadow: 0 1px 1px #ffffff inset, 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.has-switch input[type='checkbox'] {
  display: none;
}
.has-switch span {
  cursor: pointer;
  float: left;
  font-size: 11px;
  font-weight: 400;
  height: 24px;
  line-height: 15px;
  margin: 0;
  padding-bottom: 6px;
  padding-top: 5px;
  position: relative;
  text-align: center;
  text-indent: -10px;
  width: 50%;
  z-index: 1;
  transition: 0.25s ease-out;
}
.has-switch span.switch-left {
  background-color: $primary;
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-radius: 30px 0 0 30px;
  color: #ffffff;
}
.has-switch .switch-off span.switch-left {
  background-color: $gray-400 !important;
}
.has-switch span.switch-right {
  border-radius: 0 30px 30px 0;
  background-color: $primary;
  color: #ffffff;
  text-indent: 1px;
}
.has-switch .switch-off span.switch-right {
  background-color: $gray-400 !important;
}

.has-switch label {
  border-radius: 12px;
  float: left;
  height: 22px;
  margin: 1px -13px;
  padding: 0;
  position: relative;
  vertical-align: middle;
  width: 22px;
  z-index: 100;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.has-switch .switch-on .fa-check:before {
  margin-left: 10px;
}
.has-switch:hover .switch-on label {
  margin: 1px -17px;
  width: 26px;
}
.has-switch:hover .switch-off label {
  margin: 1px -13px;
  width: 26px;
}

// Sizing

.has-switch-sm {
  &.has-switch {
    span {
      height: 18px;
      line-height: 18px;
      padding: 0;
    }
    label {
      height: 16px;
      width: 16px;
      margin: 1px -8px;
    }

    .switch-off {
      label {
        margin: 1px -13px;
      }
    }

    &:hover {
      .switch-on label {
        margin: 1px -11px;
        width: 20px;
      }

      .switch-off label {
        margin: 1px -13px;
        width: 20px;
      }
    }
  }
}
