.react-checkbox-tree {
  font-size: 0.8rem;

  label {
    display: flex;
    align-items: center;
    flex-grow: 2;
    padding: 0.5rem;
  }

  .rct-text {
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid mix(rgba(225, 223, 240, 0.25), black, 95%);
  }

  .rct-collapse,
  .rct-collapse.rct-collapse-btn {
    display: flex;
    align-items: center;
    padding: 0;
    margin-right: 0.25rem;
    order: 2;
  }

  .rct-node-icon,
  .rct-checkbox {
    display: flex;
    padding: 0;
    margin-right: 0.25rem;
  }

  .rct-node-leaf > .rct-text > .rct-collapse > .rct-icon {
    padding: 0;
  }

  ol > li > ol {
    padding-left: 0;
    margin-left: 1rem;
  }
}
