// ArchitectUI Themes Variables
@import 'themes/layout-variables';

// RESET CSS
@import 'main/reset';

// LAYOUT
@import 'layout/layout';

// util
@import 'utils/animate';
@import 'utils/comps-animations';

// Perfect Scrollbar
@import 'components/perfect-scrollbar/perfect-scrollbar';

// Hamburger Button
@import 'components/hamburgers/hamburgers';

// Drawer
@import 'components/drawer/drawer';

// Notifications
@import 'components/notifications/toastify';

// Block loading
@import 'components/block-loading/block-loading';

// Popovers & Tooltips
@import 'components/popovers-tooltips/popovers-tooltips';

// Calendar
@import 'components/calendar/calendar';

// Icons
@import 'components/icons/linearicons';

// FORMS
//@import 'components/forms/datepicker/datepicker';
@import 'components/forms/rangeslider/rangeslider';
@import 'components/forms/toggle-switch/switch';
@import 'components/forms/typeahead/typeahead';

// DEMO
@import 'themes/theme-options'; //색상 옵션

// Responsive
@import 'layout/responsive/responsive-base';

//bootstrap v5
@import 'bootstrap-vr5.0.0/bootstrap.min.css';

// Custom
@import 'pnt/base.css';

// Custom
@import 'main/root';
@import 'main/layout';
@import 'main/dropdown';
@import 'main/timeline';
@import 'main/dashboards';
@import 'main/table';
@import 'main/select';
@import 'main/datepicker';
@import 'main/map';
@import 'main/custom';
@import 'main/toastAlert';
@import 'main/modals';
@import 'main/forms';
@import 'main/alert';
