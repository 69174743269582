@import '../../_variables';
@import '../../theme/color';

html[color-theme='pnt-navy'] {
  --table-th: #{$navy-depth-4};
  --th-color: #3e3d5d;
  --td-border: rgba(0, 0, 0, 0.1);
}
html[color-theme='pnt-dark'] {
  --table-th: #{$dark-depth-2};
  --th-color: #{$f-w-1};
  --td-border: rgba(0, 0, 0, 0.2);
}
.tableWrapper {
  height: 100%;
  overflow: auto;

  .headerItem {
    background-color: var(--table-th);
    color: var(--th-color) !important;
    border-bottom: 1px solid transparent !important;
  }
  th {
    border-right: 1px solid var(--td-border);
    border-bottom: transparent;
    font-size: 0.9rem;
  }
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
  }

  tr {
    td:nth-last-child(1) {
      border-left: 3px solid var(--td-border);
    }
    th:nth-last-child(1) {
      border-left: 3px solid var(--td-border);
    }
  }
}
