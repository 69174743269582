//////////////////////////////
/* Css link*/
@import '../default/_default';
@import '../../_variables';

// ---------------------------------------------------------
// ※-------------------------------------------------------
// light 테마의 기본 색상은 하단에서 수정해주세요.
// $brand, $secondary, $success, $warning, $danger, $info, $gray, $light-gray, $dark-gray
// 위 9개 종류는 고정입니다.
// ---------------------------------------------------------

$brand: #e5891f;
$secondary: #9482ec;
$success: #81be30;
$warning: #e8bb28;
$danger: #f14665;
$info: #5ab1a3;

$light-gray: rgba(225, 223, 240, 0.1);
$gray: rgba(225, 223, 240, 0.25);
$dark-gray: rgba(225, 223, 240, 0.4);

//-------------------------------------------------------------
// ※----------------------------------------------------------
// 하단 내용은 특별한 일이 없는 한 고정입니다.-------------------
//-------------------------------------------------------------

$brand-d4: mix($brand, black, 40%);
$brand-d3: mix($brand, black, 55%);
$brand-d2: mix($brand, black, 70%);
$brand-d1: mix($brand, black, 83%);
$brand-l4: mix($brand, white, 80%);
$brand-l3: mix($brand, white, 55%);
$brand-l2: mix($brand, white, 30%);
$brand-l1: mix($brand, white, 10%);

$secondary-d4: mix($secondary, black, 40%);
$secondary-d3: mix($secondary, black, 55%);
$secondary-d2: mix($secondary, black, 70%);
$secondary-d1: mix($secondary, black, 83%);
$secondary-l4: mix($secondary, white, 80%);
$secondary-l3: mix($secondary, white, 55%);
$secondary-l2: mix($secondary, white, 30%);
$secondary-l1: mix($secondary, white, 10%);

$success-d4: mix($success, black, 40%);
$success-d3: mix($success, black, 55%);
$success-d2: mix($success, black, 70%);
$success-d1: mix($success, black, 83%);
$success-l4: mix($success, white, 82%);
$success-l3: mix($success, white, 60%);
$success-l2: mix($success, white, 35%);
$success-l1: mix($success, white, 18%);

$warning-d4: mix($warning, black, 40%);
$warning-d3: mix($warning, black, 58%);
$warning-d2: mix($warning, black, 75%);
$warning-d1: mix($warning, black, 90%);
$warning-l4: mix($warning, white, 75%);
$warning-l3: mix($warning, white, 55%);
$warning-l2: mix($warning, white, 35%);
$warning-l1: mix($warning, white, 20%);

$danger-d4: mix($danger, black, 40%);
$danger-d3: mix($danger, black, 55%);
$danger-d2: mix($danger, black, 70%);
$danger-d1: mix($danger, black, 83%);
$danger-l4: mix($danger, white, 75%);
$danger-l3: mix($danger, white, 50%);
$danger-l2: mix($danger, white, 30%);
$danger-l1: mix($danger, white, 10%);

$info-d4: mix($info, black, 40%);
$info-d3: mix($info, black, 55%);
$info-d2: mix($info, black, 70%);
$info-d1: mix($info, black, 83%);
$info-l4: mix($info, white, 75%);
$info-l3: mix($info, white, 50%);
$info-l2: mix($info, white, 30%);
$info-l1: mix($info, white, 10%);

$gray-d: mix($gray, black, 30%);
$gray-l: mix($gray, white, 30%);
$light-gray-d: mix($light-gray, black, 30%);
$light-gray-l: mix($light-gray, white, 30%);
$dark-gray-d: mix($dark-gray, black, 30%);
$dark-gray-l: mix($dark-gray, white, 30%);

$depth-base: #1f1f27;
$depth-1: mix($depth-base, #ffffff, 98%);
$depth-2: mix($depth-base, #ffffff, 95%);
$depth-3: mix($depth-base, #ffffff, 92%);
$depth-4: mix($depth-base, #ffffff, 89%);
$depth-5: mix($depth-base, #ffffff, 87%);
$depth-6: mix($depth-base, #ffffff, 85%);
$depth-7: mix($depth-base, #ffffff, 83%);
$depth-8: mix($depth-base, #ffffff, 80%);
$depth-9: mix($depth-base, #ffffff, 70%);
$depth-10: mix($depth-base, #ffffff, 20%);

// ---------------------------------------------------------
// ※-------------------------------------------------------
// 하단의 내용은 수정할 수 있지만, 변수를 추가, 제거할 순 없습니다.
// 변수 추가, 제거를 원하면 먼저 css/theme/default/default/scss 에서 수정한뒤
// 각 theme 폴더안에 동일하게 적용해주세요.
// ---------------------------------------------------------

//웹 - 공통txt
$base-txt: $f-w-2;

//웹 - 바디
//(그라디언트만 있습니다. solid로 바꾸고 싶으면, gradient 둘다에 동일색을 넣으세요.)
$whole-body-gradient-start: mix($depth-base, #000000, 80%);
$whole-body-gradient-end: mix($depth-base, #000000, 80%);
$body-title-bg: transparent;

//대시보드 편집 배경
$dashboard-edit-bg: rgb(80, 76, 87);

//웹 - 헤더
$header-bg: $depth-1;
$header-logobox: $depth-5; //사이드바와 같은 색상
$header-menu-text1: $f-w-1;
$header-menu-text2: $f-w-5;
$header-widget-icon: gray;

//웹 - 사이드바
$menu-body-bg: $depth-5;
$menu-hover-bg: rgba(196, 181, 238, 0.2);
$menu-active-bg: rgba(196, 181, 238, 0.2);
$menu-line-color: #48455c;
$menu-icon-color: #a7a2c4;
$menu-down-icon-color: #706966;
$menu-main-txt: #a7a2c4;
$menu-sub-txt: $f-w-1;
$menu-active-txt: rgb(187, 170, 255);

$menu-icon-filter: invert(75%) sepia(16%) saturate(459%) hue-rotate(209deg) brightness(88%) contrast(80%);
$menu-icon-active-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);

//웹 - 푸터
$footer-bg: $depth-base;
$footer-txt: $f-w-6;

//모바일
$app-header-bg: #f5f5fa;

//카드
$card-body-bg: $depth-1;
$card-header-bg: transparent;
$card-header-border: transparent;
$card-icon-color: $brand;
$card-icon-filter: invert(49%) sepia(82%) saturate(2656%) hue-rotate(1deg) brightness(105%) contrast(103%);
$card-title-color: $brand;
$card-tab: mix($depth-base, #000000, 80%);
$card-tab-li: $depth-base;
$card-tab-li-active: $depth-1;
$card-tab-txt: $f-w-1;
$card-tab-li-txt: $f-w-6;
$card-tab-2: $secondary;
$card-button-body: $depth-3;

//이너 아이템
$inner-card-body-bg: $depth-base;
$inner-card-header-bg: $depth-base;
$inner-card-border: $depth-base;

//테이블
$table-border: $gray-900;
$table-inner-border: rgba(0, 0, 0, 0.2);
$table-inner-bottom-border: rgba(0, 0, 0, 0.05);
$table-header-border: transparent;
$table-2row-bg: $depth-5;
$table-thead-bg: $depth-2;
$table-tbody-bg: $depth-5;
$table-hover-bg: $depth-7;
$table-clicked-bg: $depth-8;
$table-clicked-txt: white;
$table-th-txt: $f-w-1;
$table-td-txt: $f-w-4;

//라디오
$radio-checked-bg: $secondary;

//토글
$toggle-bg: rgba(255, 255, 255, 0.3);
$toggle-bar-bg: white;

//네임플레이트
$nameplate-bg: $depth-6;
$nameplate-txt: $f-w-2;

//스크롤바
$scrollbar-bg: rgba(255, 255, 255, 0.2);
$scrollbar-track-bg: rgba(255, 255, 255, 0.1);

//form 기본
$form-bg: $depth-7;
$form-border: $depth-6;
$form-txt: $f-w-3;

$form-active-bg: $secondary;
$form-active-border: $gray-600;
$form-active-txt: $f-w-1;

$form-active-bg-light: $gray-700;
$form-active-txt-light: $f-w-1;

$form-disable-bg: $depth-3;
$form-disable-txt: $f-w-7;

$form-placeholder-txt: $f-w-7;
$form-hover-bg: $depth-9;

//인풋
$input-bg: $depth-6;
$input-border: $form-border;

//위젯
$widget-active-bg: $depth-9;
$widget-active-border: white;
$widget-active-txt: white;

//블랙화이트
$trans-black: white;

/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////

//적용
#root .app-container.app-theme-pnt-dark {
  * {
    font-weight: 400;
  }
  .pnt-txt {
    font-weight: 400;
  }
  .color-brand,
  .color-secondary,
  .color-success,
  .color-info,
  .color-warning,
  .color-danger,
  .txt-bold {
    font-weight: 600 !important;
  }
  & span[class*='sensor_img-'],
  & span[class*='widget_img-'] {
    filter: invert(1);
  }
  @include theme-default();
}
