@import '../variables';

#select-container,
#modal-select-container {
  .container_tree_item {
    background-color: $f-w-2;
    color: $gray-700 !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .react-select__menu .react_map_menu_tree.react-select__menu-list .react-select__option:hover {
    background-color: $f-b-9 !important;
  }
  .react-select__menu .react_menu_tree.react-select__menu-list .react-select__option:hover {
    background-color: $f-b-9 !important;
  }
}
