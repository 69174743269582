// App Header Alert(종)

// 좌측 Badge
.badge {
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
  min-width: 19px;
}

.badge-dot {
  text-indent: -999em;
  padding: 0;
  width: 8px;
  height: 8px;
  border: transparent solid 1px;
  border-radius: 30px;
  min-width: 2px;
}

.badge-dot-xl {
  width: 18px;
  height: 18px;
  position: relative;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: $border-radius;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -5px;
    background: $white;
  }
}

// alert nav
.nav-item {
  .nav-link {
    font-weight: normal;
  }
}

.nav-item {
  &.nav-item-header {
    text-transform: uppercase;
    font-size: $font-size-base / 1.2;
    color: $gray-600;
    font-weight: bold;
    padding: $nav-link-padding-y $nav-link-padding-x;
  }

  &.nav-item-btn {
    padding: $nav-link-padding-y $nav-link-padding-x;
  }

  &.nav-item-divider {
    margin: $nav-link-padding-y 0;
    height: 1px;
    overflow: hidden;
    background: $gray-300;
  }
}

.nav {
  .badge {
    margin-left: 8px;
  }
}
