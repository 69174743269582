//css link
@import '_variables';

//////////////////////////////////////////////////////
/* 3. 자주 쓰는 문법  */
//
//@mixin brightness($color) {
//  background-color: $color;
//  &.bg-light {
//    background-color: mix($color, black, 83%) !important;
//  }
//  &.bg-dark {
//    background-color: mix($color, white, 80%) !important;
//  }
//}
//.bg-brand {
//  @include brightness($default-brand);
//}
//.bg-secondary {
//  @include brightness($default-secondary);
//}
//.bg-success {
//  @include brightness($default-success);
//}
//.bg-info {
//  @include brightness($default-info);
//}
//.bg-warning {
//  @include brightness($default-warning);
//}
//.bg-danger {
//  @include brightness($default-danger);
//}
//.bg-gray {
//  @include brightness($gray-500);
//}
//.bg-white {
//  background-color: white !important;
//}
//.bg-black {
//  background-color: black !important;
//}

//////////////////////////////////////////////////////
/* 3. 자주 쓰는 문법  */

//font
.ws-none {
  white-space: nowrap;
}
//필터
.img-filter-brand {
  filter: invert(49%) sepia(82%) saturate(2656%) hue-rotate(1deg) brightness(105%) contrast(103%) !important;
}
.img-filter-secondary {
  filter: invert(34%) sepia(83%) saturate(801%) hue-rotate(220deg) brightness(87%) contrast(85%) !important;
}
.img-filter-success {
  filter: invert(62%) sepia(62%) saturate(549%) hue-rotate(44deg) brightness(100%) contrast(98%) !important;
}
.img-filter-warning {
  filter: invert(95%) sepia(8%) saturate(6878%) hue-rotate(330deg) brightness(104%) contrast(105%) !important;
}
.img-filter-danger {
  filter: invert(20%) sepia(100%) saturate(3000%) hue-rotate(359deg) brightness(94%) contrast(100%) !important;
}
.img-filter-info {
  filter: invert(69%) sepia(41%) saturate(212%) hue-rotate(122deg) brightness(88%) contrast(89%) !important;
}
.img-filter-gray {
  filter: invert(76%) sepia(0%) saturate(1126%) hue-rotate(106deg) brightness(92%) contrast(83%) !important;
}
.img-filter-white {
  filter: invert(1) !important;
}
.img-filter-black {
  filter: none !important;
}

//rotete
.rotate-1 {
  transform: rotate(-45deg);
}
.rotate-2 {
  transform: rotate(-90deg);
}
.rotate-3 {
  transform: rotate(-135deg);
}
.rotate-4 {
  transform: rotate(-180deg);
}
.rotate-5 {
  transform: rotate(-225deg);
}
.rotate-6 {
  transform: rotate(-270deg);
}
.rotate-7 {
  transform: rotate(-315deg);
}
.rotate-8 {
  transform: rotate(-360deg);
}

//scale
.scale-1 {
  transform: scale(1.2);
}
.scale-2 {
  transform: scale(1.5);
}
.scale-3 {
  transform: scale(2);
}
.scale-4 {
  transform: scale(3);
}
.scale-5 {
  transform: scale(5);
}

//flex 정렬
.tr {
  text-align: right;
}
.tl {
  text-align: left;
}
.tc {
  text-align: center;
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.h-100-m {
  height: calc(100% - 1rem);
}

.align-start {
  align-items: flex-start !important;
}
.align-end {
  align-items: flex-end !important ;
}
.align-center {
  align-items: center !important ;
}
.flx-start {
  justify-content: flex-start !important;
}
.flx-end {
  justify-content: flex-end !important;
}
.flx-center {
  justify-content: center !important;
}
.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  &.gap-0 {
    gap: 0;
  }
  &.gap-1 {
    gap: 0.5rem;
  }
  &.gap-2 {
    gap: 1rem;
  }
  &.gap-3 {
    gap: 2rem;
  }
  &.flx-mobile-col {
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }
  }
  &.flx-tablet-col {
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
  }
  &.flx-labtop-col {
    @media (max-width: $breakpoint-labtop) {
      flex-direction: column;
    }
  }
}
.flx-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.5rem;
  &.gap-0 {
    gap: 0;
  }
  &.gap-1 {
    gap: 0.5rem;
  }
  &.gap-2 {
    gap: 1rem;
  }
  &.gap-3 {
    gap: 2rem;
  }
}
.flx-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flx-full {
  & > * {
    width: 100%;
  }
}
.flx-shrink-0 {
  flex-shrink: 0;
}
.flx-1 {
  flex: 1;
}

.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}

.h-10 {
  height: 10% !important;
}
.h-20 {
  height: 20% !important;
}
.h-30 {
  height: 30% !important;
}
.h-40 {
  height: 40% !important;
}
.h-60 {
  height: 60% !important;
}
.h-70 {
  height: 70% !important;
}
.h-80 {
  height: 80% !important;
}
.h-90 {
  height: 90% !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 0.2rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mr-4 {
  margin-right: 2rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 0.2rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.ml-4 {
  margin-left: 2rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.2rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 2rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.2rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 2rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 0.2rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.pr-4 {
  padding-right: 2rem !important;
}
.pr-5 {
  padding-right: 3rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 0.2rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
.pl-4 {
  padding-left: 2rem !important;
}
.pl-5 {
  padding-left: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.2rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pmt-4 {
  padding-top: 2rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.2rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 2rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}

.br-0 {
  border-radius: 0 !important;
}
.br-1 {
  border-radius: 0.5rem !important;
}
.br-2 {
  border-radius: 1rem !important;
}
.br-3 {
  border-radius: 2rem !important;
}
.br-4 {
  border-radius: 3rem !important;
}
.br-5 {
  border-radius: 100rem !important;
}

.v-visible {
  visibility: visible !important;
}
.v-hidden {
  visibility: hidden !important;
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.filter-none {
  filter: none !important;
}

.min-w-none {
  min-width: unset !important;
}
.max-w-none {
  max-width: unset !important;
}
.min-h-none {
  min-height: unset !important;
}
.max-h-none {
  max-height: unset !important;
}

//--[옵션] 모바일에서만 보이는 것
.show__under-mobile {
  display: none !important;
  @media (max-width: $breakpoint-mobile) {
    display: flex !important;
  }
}
.show__under-tablet {
  display: none !important;
  @media (max-width: $breakpoint-tablet) {
    display: block !important;
  }
}
.show__under-desktop {
  display: none !important;
  @media (max-width: $breakpoint-desktop) {
    display: block !important;
  }
}
.show__only-labtop {
  display: none !important;
  @media (min-width: $breakpoint-labtop) and (max-width: $breakpoint-widemonitor) {
    display: block !important;
  }
}
.hide__under-mobile {
  display: block !important;
  @media (max-width: $breakpoint-mobile) {
    display: none !important;
  }
}

.mini-box {
  border-radius: $form-radius;
  box-shadow: $form-shadow;
  padding: 1rem;
  background-color: white;
}

.shadow-none {
  box-shadow: none !important;
}
.shadow-big {
  box-shadow: $card-shadow !important;
}
.shadow-small {
  box-shadow: $form-shadow !important;
}

.material-icons-round {
  &.md-18 {
    font-size: 18px;
  }
  &.md-20 {
    font-size: 20px;
  }
  &.md-22 {
    font-size: 22px;
  }
  &.md-24 {
    font-size: 24px;
  }
  &.md-30 {
    font-size: 30px;
  }
  &.md-36 {
    font-size: 36px;
  }
  &.md-48 {
    font-size: 48px;
  }
}

.grid-container {
  width: 100%;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(30%, auto));

  // 50 to 1000
  $base: 50;
  @for $i from 1 through 20 {
    $calcValue: $base * $i;
    &.item-grid-#{$calcValue} {
      grid-template-columns: repeat(auto-fill, minmax(#{$calcValue}px, 1fr));
    }
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  & > div {
    flex: 1 1 0;
  }
  &.item-flex-2 {
    & > div {
      flex: 1 1 40%;
    }
  }
  &.item-flex-3 {
    & > div {
      flex: 1 1 30%;
    }
  }
  &.item-flex-4 {
    & > div {
      flex: 1 1 20%;
    }
  }
  &.item-flex-5 {
    & > div {
      flex: 1 1 0;
    }
  }
  &.item-flex-6 {
    & > div {
      flex: 1 1 40%;
    }
  }
}
