.Toastify {
  // Toast container width overriding
  --toastify-toast-width: 240px;

  // Toast container
  & .Toastify__toast-container {
    width: 240px;
    padding: 0.25rem;
    & .Toastify__toast {
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      min-height: 64px;
    }
    & .Toastify__toast-body {
      min-width: 0;
      padding: 0 0.25rem;

      & > div {
        min-width: 0;
      }
      & .toast-contents {
        min-width: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.toast-primary {
  color: #545cd8 !important;
}

.toast-danger {
  color: #d92550 !important;
}
