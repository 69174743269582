@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin box-shadow($shadow, $inset: false) {
  @if $inset {
    -webkit-box-shadow: $inset, $shadow;
    -moz-box-shadow: $inset, $shadow;
    box-shadow: $inset, $shadow;
  } @else {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
  }
}

@mixin display-flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($value) {
  -webkit-box-flex: $value;
  -moz-box-flex: $value;
  -webkit-flex: $value;
  -ms-flex: $value;
  flex: $value;
}

@mixin placeholder($placeholder-color) {
  // Firefox
  &::-moz-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }

  // Internet Explorer 10+
  &:-ms-input-placeholder {
    color: $placeholder-color;
  }

  // Safari and Chrome
  &::-webkit-input-placeholder {
    color: $placeholder-color;
  }
}
// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

@mixin sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}
