@import '../../theme/color';

.range-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 33rem;
  height: 100%;
  background-color: var(--table-thead-bg);

  gap: 1rem;
  border-radius: 2rem;
  padding: 1rem 2rem;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.12);

  & .date-type-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 0.2rem 1rem;
    background-color: var(--form-bg);
    gap: 1rem;

    & .date-type {
      display: flex;
      width: 80%;
      height: 1.5rem;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    & .date-clickable {
      background-color: var(--secondary);
      color: white !important;
      border-radius: 0.6rem;
    }
  }

  & .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .pnt-datepicker-container {
    & .react-datepicker__triangle {
      display: none;
    }

    & .react-datepicker__day-name:nth-child(1) {
      color: #ff5555 !important; /* 일요일 */
    }

    & .react-datepicker__day:nth-child(1) {
      color: red !important;
      //pointer-events: none;
    }

    & .react-datepicker__day {
      &--outside-month {
        visibility: hidden;
      }
    }

    & .react-datepicker__month:not(.react-datepicker__monthPicker) {
      height: 12rem;
    }
  }
}
