@import '../_font';
@import '../variables';

.widgetCounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.title {
  margin: 0;
  padding: 0;
  @include p3-n(12px);
}
.subTitle {
  margin: 0;
  padding: 0;
  @include p5-n(8px);
}

.widget-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 20px;
  border-bottom: 1px solid $gray-200;
}
.widget-name {
  font-weight: 700 !important;
}

.widget-desc {
  margin-bottom: 0;
}

.tags-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 0.25rem;
}

.pnt-widget-tag,
.pnt-widget-tag:focus,
.pnt-widget-tag:active {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  font-size: 0.7rem;
  background-color: transparent;
  border-radius: 15px;
  padding: 0 0.5rem;
  border: 1px solid var(--brand);
  font-weight: 600 !important;

  &:not(.show-only) {
    color: var(--brand);
  }

  &.show-only,
  &.selected {
    color: #fbfbfb;
    border-color: var(--depth-base);
    background-color: var(--brand);
    .material-icons-round {
      color: #fbfbfb !important;
    }
  }

  &:not(.show-only):not(.selected):not(.recommended) {
    border: 1px solid var(--depth-9);
    color: var(--depth-9);
  }

  &.show-only {
    pointer-events: none;
  }

  .material-icons-round {
    font-size: 0.7rem;
    color: var(--brand) !important;
  }
}

.tags-filter-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  padding: 0.25rem;
}
