////////////////////////////////////////
//아이콘 세팅

$icon: 40px;
$icon-gap: 20px;

$p1: calc((#{$icon} * -0) - (#{$icon-gap} * 0));
$p2: calc((#{$icon} * -1) - (#{$icon-gap} * 1));
$p3: calc((#{$icon} * -2) - (#{$icon-gap} * 2));
$p4: calc((#{$icon} * -3) - (#{$icon-gap} * 3));
$p5: calc((#{$icon} * -4) - (#{$icon-gap} * 4));
$p6: calc((#{$icon} * -5) - (#{$icon-gap} * 5));
$p7: calc((#{$icon} * -6) - (#{$icon-gap} * 6));
$p8: calc((#{$icon} * -7) - (#{$icon-gap} * 7));
$p9: calc((#{$icon} * -8) - (#{$icon-gap} * 8));
$p10: calc((#{$icon} * -9) - (#{$icon-gap} * 9));

@mixin bgp($x, $y) {
  background-position: $x $y;
}
@mixin icons {
  display: block;
  background-repeat: no-repeat;
  border: none;
  font-size: 0;
  width: $icon;
  height: $icon;
  background-size: calc((#{$icon} * 10) + (#{$icon-gap} * 9)); //가로 10줄
}

span[class^='s_'] {
  @include icons();
  background-image: url('../images/sensorIcon.svg');
}
span[class^='w_'] {
  @include icons();
  background-image: url('../images/sh_widget_icon.svg');
}

////////////////////////////////////////
//widget_img
.w_chart_table {
  @include bgp($p1, $p1);
}
.w_dashboard {
  @include bgp($p2, $p1);
}
.w_monitor {
  @include bgp($p3, $p1);
}
.w_filter_table_line {
  @include bgp($p4, $p1);
}
.w_filter_line {
  @include bgp($p5, $p1);
}
.w_filter_map {
  @include bgp($p6, $p1);
}
.w_cardmonitor {
  @include bgp($p7, $p1);
}
.w_chart {
  @include bgp($p8, $p1);
}
.w_pie {
  @include bgp($p9, $p1);
}
.w_table {
  @include bgp($p10, $p1);
}

.w_map {
  @include bgp($p1, $p2);
}
.w_pie_table {
  @include bgp($p2, $p2);
}
.w_battery_chart {
  @include bgp($p3, $p2);
}
.w_pie_chart {
  @include bgp($p4, $p2);
}
.w_line {
  @include bgp($p5, $p2);
}
.w_line_chart {
  @include bgp($p6, $p2);
}
.w_chart_search_table {
  @include bgp($p7, $p2);
}
.w_search_table {
  @include bgp($p8, $p2);
}
.w_filter_cardmonitor {
  @include bgp($p9, $p2);
}
.w_search_map_chart_table {
  @include bgp($p10, $p2);
}

.w_search_line {
  @include bgp($p1, $p3);
}
.w_search_map_line {
  @include bgp($p2, $p3);
}

//sensor-img
.s_temperature {
  @include bgp($p1, $p1);
}
.s_temperature2 {
  @include bgp($p2, $p1);
}
.s_humidity {
  @include bgp($p3, $p1);
}
.s_relativeHumidity {
  @include bgp($p4, $p1);
}
.s_ambientLight {
  @include bgp($p5, $p1);
}
.s_barometricPressure {
  @include bgp($p6, $p1);
}
.s_seismicIntensity {
  @include bgp($p7, $p1);
}
.s_vibration {
  @include bgp($p8, $p1);
}
.s_soundNoise {
  @include bgp($p9, $p1);
}
.s_discomfortIndex {
  @include bgp($p10, $p1);
}

.s_O2 {
  @include bgp($p1, $p2);
}
.s_LEL {
  @include bgp($p2, $p2);
}
.s_H2S {
  @include bgp($p3, $p2);
}
.s_CO {
  @include bgp($p4, $p2);
}
.s_CO2 {
  @include bgp($p5, $p2);
}
.s_SO2 {
  @include bgp($p6, $p2);
}
.s_eTVOC {
  @include bgp($p7, $p2);
}
.s_eCO2 {
  @include bgp($p8, $p2);
}
.s_etCO2 {
  @include bgp($p9, $p2);
}
.s_inCO2 {
  @include bgp($p10, $p2);
}

.s_heartbeat {
  @include bgp($p1, $p3);
}
.s_highBloodPressure {
  @include bgp($p2, $p3);
}
.s_lowBloodPressure {
  @include bgp($p3, $p3);
}
.s_bloodPressure {
  @include bgp($p4, $p3);
}
.s_highBloodPressure2 {
  @include bgp($p5, $p3);
}
.s_lowBloodPressure2 {
  @include bgp($p6, $p3);
}
.s_bloodPressure2 {
  @include bgp($p7, $p3);
}
.s_pulseRate {
  @include bgp($p8, $p3);
}
.s_heatStroke {
  @include bgp($p9, $p3);
}
.s_accelerationXaxis {
  @include bgp($p10, $p3);
}

.s_accelerationYaxis {
  @include bgp($p1, $p4);
}
.s_accelerationZaxis {
  @include bgp($p2, $p4);
}
.s_angularXaxis {
  @include bgp($p3, $p4);
}
.s_angularYaxis {
  @include bgp($p4, $p4);
}
.s_angularZaxis {
  @include bgp($p5, $p4);
}
.s_pir {
  @include bgp($p6, $p4);
}
.s_movement {
  @include bgp($p7, $p4);
}
.s_battery {
  @include bgp($p8, $p4);
}
.s_nappyBig {
  @include bgp($p9, $p4);
}
.s_nappyGas {
  @include bgp($p10, $p4);
}

.s_nappySmall {
  @include bgp($p1, $p5);
}
.s_calories {
  @include bgp($p2, $p5);
}
.s_step {
  @include bgp($p3, $p5);
}
.s_distance {
  @include bgp($p4, $p5);
}
.s_sleep {
  @include bgp($p5, $p5);
}
.s_height {
  @include bgp($p6, $p5);
}
.s_weight {
  @include bgp($p7, $p5);
}
.s_weight2 {
  @include bgp($p8, $p5);
}
.s_fire {
  @include bgp($p9, $p5);
}
.s_stress {
  @include bgp($p10, $p5);
}

.s_capacity {
  @include bgp($p1, $p6);
}
.s_crack {
  @include bgp($p2, $p6);
}
.s_dust {
  @include bgp($p3, $p6);
}
.s_dosage {
  @include bgp($p4, $p6);
}
.s_totalDosage {
  @include bgp($p5, $p6);
}
.s_dropCount {
  @include bgp($p6, $p6);
}
.s_dropRate {
  @include bgp($p7, $p6);
}
.s_packetCounter {
  @include bgp($p8, $p6);
}
.s_spO2 {
  @include bgp($p9, $p6);
}
.s_respirationRate {
  @include bgp($p10, $p6);
}

.s_ipi {
  @include bgp($p1, $p7);
}
.s_iHighBloodPressure {
  @include bgp($p2, $p7);
}
.s_iLowBloodPressure {
  @include bgp($p3, $p7);
}
.s_iBloodPressure {
  @include bgp($p4, $p7);
}
.s_iHighBloodPressure2 {
  @include bgp($p5, $p7);
}
.s_iLowBloodPressure2 {
  @include bgp($p6, $p7);
}
.s_iBloodPressure2 {
  @include bgp($p7, $p7);
}
.s_afBurden {
  @include bgp($p8, $p7);
}
.s_totalWearTimeSec {
  @include bgp($p9, $p7);
}
.s_analyzableWearTimeSec {
  @include bgp($p10, $p7);
}

.s_afEpisodeCnt {
  @include bgp($p1, $p8);
}
.s_meanAfDurSec {
  @include bgp($p2, $p8);
}
.s_minAfDurSec {
  @include bgp($p3, $p8);
}
.s_medianAfDurSec {
  @include bgp($p4, $p8);
}
.s_maxAfDurSec {
  @include bgp($p5, $p8);
}
.s_totalAfDurSec {
  @include bgp($p6, $p8);
}
.s_button1Count {
  @include bgp($p7, $p8);
}
.s_button2Count {
  @include bgp($p8, $p8);
}
.s_ecg {
  @include bgp($p9, $p8);
}
.s_rate {
  @include bgp($p10, $p8);
}

.s_vtbi {
  @include bgp($p1, $p9);
}
.s_infusedVol {
  @include bgp($p2, $p9);
}
.s_bi {
  @include bgp($p3, $p9);
}
.s_conc {
  @include bgp($p4, $p9);
}
.s_dose {
  @include bgp($p5, $p9);
}
.s_pcaBolus {
  @include bgp($p6, $p9);
}
.s_pi {
  @include bgp($p7, $p9);
}
.s_bloodGlucose {
  @include bgp($p8, $p9);
}
.s_stride {
  @include bgp($p9, $p9);
}
.s_speed {
  @include bgp($p10, $p9);
}
.s_sensor {
  @include bgp($p1, $p10);
}
