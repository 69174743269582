@import '../variables';

.location_icon {
  cursor: pointer;
  color: $gray-400 !important;
  &:hover {
    color: $default-brand !important;
  }
}

.sensor_img {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: gray;
  overflow: hidden;
  text-align: center;

  & > span {
    filter: invert(1);
  }
  & .status {
    position: absolute;
    right: 5px;
    bottom: 5px;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
      font-size: 14px;
      color: white !important;
    }
  }
}

.sensor_info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start !important;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  margin-left: 0.5rem;
}
