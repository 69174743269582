@import '../pnt/theme/color';
@import 'react-datepicker/dist/react-datepicker';
@import '../themes/default/variables';

$datepicker__muted-color: $gray-400;
$datepicker__triangle-size: 6px;
$datepicker__border-color: $gray-300;
$datepicker__background-color: $white;
$datepicker__item-size: 2rem;
$datepicker__navigation-disabled-color: darken($datepicker__muted-color, 5%);
$border-radius: 0.25rem;
$font-size-base: 0.75rem;

html[color-theme='pnt-navy'] {
  --month-hover: #f0f0f0;
  --week-hover: #f0f0f0;
}

html[color-theme='pnt-dark'] {
  --month-hover: #45454c;
  --week-hover: #45454c;
}

.pnt-datepicker-container {
  margin: 0;
  padding: 0;
  display: block;

  &.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }

  // input 영역
  & .form-control {
    width: 100%;
    height: 28px;
    background-color: var(--form-bg);
    border: 1px solid var(--form-border);
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
    font-size: 0.8rem;

    &.form-disable {
      background-color: var(--form-disable-bg);
      color: $gray-500;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(0.98);
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 1px var(--form-active-border);
    }
  }

  //input 달력 아이콘
  & .input-group-prepend {
    & .input-group-text {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      background-color: var(--form-disable-bg);
      border: 1px solid var(--form-border);
      padding: 0 0.5rem;

      & .material-icons-round {
        margin-top: -1px;
      }
    }
  }

  // 달력 콘텐츠
  & .react-datepicker {
    display: flex;
    position: relative;
    background-color: var(--depth-base);
    border-radius: 0;
    border: 1px solid var(--form-border);
    box-shadow: $box-shadow-default;

    *::-webkit-scrollbar-track {
      background-color: var(--table-thead-bg);
    }

    *::-webkit-scrollbar-thumb {
      background-color: var(--form-bg);
    }

    // 달력 오픈 시 인풋 방향에 있는 화살표
    .react-datepicker__triangle {
      border-bottom-color: var(--form-bg);
      border-top-color: var(--form-bg);

      &::before {
        border-bottom-color: var(--form-border);
        border-top-color: var(--form-border);
      }

      .react-datepicker-popper {
        &[data-placement^='bottom'] {
          margin-top: $datepicker__triangle-size + 2px;

          .react-datepicker__triangle {
            @extend %triangle-arrow-up;
          }
        }

        &[data-placement^='top'] {
          margin-bottom: $datepicker__triangle-size + 2px;

          .react-datepicker__triangle {
            @extend %triangle-arrow-down;
          }
        }

        &[data-placement^='right'] {
          margin-left: $datepicker__triangle-size;

          .react-datepicker__triangle {
            left: auto;
            right: 42px;
          }
        }

        &[data-placement^='left'] {
          margin-right: $datepicker__triangle-size;

          .react-datepicker__triangle {
            left: 42px;
            right: auto;
          }
        }
      }
    }

    //달력 월 변경 화살표
    & .react-datepicker__navigation {
      top: 12px;
      background: none;
      line-height: $datepicker__item-size;
      text-align: center;
      position: absolute;
      padding: 0;
      z-index: 1;
      height: 10px;
      width: 10px;
      text-indent: -999em;
      overflow: hidden;

      &--previous {
        left: 10px;

        &:hover {
          border-right-color: var(--form-active-border);
        }

        &--disabled,
        &--disabled:hover {
          border-right-color: $datepicker__navigation-disabled-color;
          cursor: default;
        }
      }

      &--next {
        &--with-time:not(&--with-today-button) {
          right: 95px;
        }

        &:hover {
          border-left-color: var(--form-active-border);
        }

        &--disabled,
        &--disabled:hover {
          border-left-color: $datepicker__navigation-disabled-color;
          cursor: default;
        }
      }
    }
  }

  // timeInput 일 경우 다음 달 이동 화살표 위치 포지션 재설정
  &.time-input {
    .react-datepicker__navigation--next,
    .react-datepicker__navigation--next:focus,
    .react-datepicker__navigation--next:active {
      border-left-color: #ccc;
      right: 72px;
    }
  }

  //전체 영역
  .datepicker-popper-display {
    z-index: 1500 !important;

    // 화살표 클릭 시 화살표가 사라지는 현상 방지
    & .react-datepicker__navigation,
    & .react-datepicker__navigation:focus,
    & .react-datepicker__navigation:active {
      border: 0.45rem solid transparent;
    }

    & .react-datepicker__navigation--previous,
    & .react-datepicker__navigation--previous:focus,
    & .react-datepicker__navigation--previous:active {
      border-right-color: #ccc;
    }

    & .react-datepicker__navigation--next,
    & .react-datepicker__navigation--next:focus,
    & .react-datepicker__navigation--next:active {
      border-left-color: #ccc;
    }

    // 개발서버에 적용되고 있었던 사이즈
    //.react-datepicker__day-name,
    //.react-datepicker__day,
    //.react-datepicker__time-name {
    //  width: 2rem;
    //  line-height: 2rem;
    //}

    .time-label-box {
      display: flex;

      div {
        width: 50%;
        line-height: 1.7rem;
        text-align: center;
        padding: 0.166rem;
      }
    }
  }

  //year 영역
  & .react-datepicker__year--container {
    & .react-datepicker__header {
      padding-bottom: 0.5rem;
      background-color: var(--table-thead-bg);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom: 1px solid var(--form-border);
    }

    & .react-datepicker__year {
      & .react-datepicker__year-wrapper {
        display: inline-block;
        max-width: unset;

        & .react-datepicker__year-text {
          border-radius: 0.25rem;
          padding: 0.5rem 1rem;

          &:hover,
          &:focus,
          &:active {
            font-weight: 700;
            background-color: var(--form-bg);
          }

          //선택 시
          &--selected,
          &--keyboard-selected {
            background-color: $default-secondary !important;
            color: $white !important;

            &:hover {
              background-color: $default-secondary;
            }
          }

          //disabled
          &--disabled {
            cursor: default;
            color: var(--depth-9) !important;
          }
        }
      }

      & .react-datepicker__year-text--in-range {
        background-color: rgba(90, 155, 255, 0.7);
        color: white !important;
        border-radius: 0;

        z-index: 5;

        &:hover,
        &:focus,
        &:active {
          color: white !important;
          font-weight: 700;
          background-color: #5a9bff !important;
        }
      }
      & .react-datepicker__year-text.react-datepicker__year-text--in-range {
        margin: 2px 0;
        border-radius: 0;
        width: 4.25rem;
      }
      & .react-datepicker__year-text--range-start,
      .react-datepicker__year-text--range-end {
        margin: 2px 0;
        width: 4.25rem;
      }

      .react-datepicker__year-text--range-start {
        border-radius: 0.25rem 0 0 0.25rem !important;
      }

      & .react-datepicker__year-text--range-end {
        border-radius: 0 0.25rem 0.25rem 0 !important;
      }
    }
  }

  //month 영역
  & .react-datepicker__month-container {
    & .react-datepicker__header {
      background-color: var(--table-thead-bg);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom: 1px solid var(--form-border);

      & .react-datepicker__current-month {
        margin-top: 0;
        color: $gray-800;
        font-weight: bold;
        font-size: $font-size-base * 1.1;
      }

      & .react-datepicker__day-names {
        background-color: var(--form-bg);
        border-top: 1px solid var(--form-border);
        margin-top: 0.5rem;
      }
    }

    //월 달력 호버
    & .react-datepicker__month-text {
      line-height: 2rem;

      &:hover {
        background-color: var(--month-hover);
      }

      &--keyboard-selected {
        background-color: initial;
        color: initial;

        &:hover {
          background-color: var(--month-hover);
        }
      }
    }

    //월 달력 disabled
    & .react-datepicker__month--disabled {
      border-left-color: $datepicker__navigation-disabled-color;
      cursor: default;
      color: var(--depth-9) !important;
    }

    //월 달력 선택 시
    & .react-datepicker__month--selected {
      background-color: $default-secondary !important;
      color: $white !important;

      &:hover {
        background-color: $default-secondary;
      }
    }

    & .react-datepicker__week {
      & .react-datepicker__day {
        &:hover,
        &:focus,
        &:active {
          font-weight: 700;
          background-color: var(--form-bg);
        }

        &.react-datepicker__day--keyboard-selected {
          background-color: $default-secondary !important;
          color: white;
        }
      }
    }

    // 주를 달력에 표현할 경우 호버 색
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      background-color: var(--week-hover);
    }

    .react-datepicker__month--in-range.react-datepicker__month-text {
      margin: 2px 0;
      width: 4.25rem;
      line-height: 2rem;
      &:hover,
      &:focus,
      &:active {
        color: white !important;
        font-weight: 700;
        background-color: #5a9bff;
      }
    }

    // rangePicker 선택 영역
    & .react-datepicker__month--in-range {
      background-color: rgba(90, 155, 255, 0.7);
      color: white !important;
      border-radius: 0;
      z-index: 5;
    }

    // rangePicker 달력 시작과 끝 부분 selected
    & .react_datepicker__month--in-range,
    .react-datepicker__month--range-start,
    .react-datepicker__month--range-end {
      color: white !important;
      background-color: $default-secondary;

      &:hover,
      &:focus,
      &:active {
        font-weight: 700;
        background-color: $default-secondary !important;
      }
    }

    & .react-datepicker__month--range-start {
      border-radius: 0.25rem 0 0 0.25rem !important;
    }

    & .react-datepicker__month--range-end {
      border-radius: 0 0.25rem 0.25rem 0 !important;
    }
  }

  //day 영역 선택 된 항목
  .react-datepicker__day {
    &--selected {
      background-color: $default-secondary !important;
      color: $white !important;

      &:hover {
        background-color: $default-secondary;
        color: $white;
      }
    }

    &--disabled {
      color: var(--depth-9) !important;
    }

    //keyboard navigation
    &--keyboard-selected {
      background-color: $default-secondary;
      color: $white !important;

      &:hover {
        background-color: $default-secondary;
      }
    }

    // datePicker 기간 선택 영역
    //영역 선택 시작
    &--range-start {
      background-color: $default-secondary !important;
      border-radius: 0.25rem 0 0 0.25rem !important;
      opacity: 1 !important;
      color: white !important;
    }

    //영역 선택 마지막
    &--range-end {
      background-color: $default-secondary !important;
      border-radius: 0 0.25rem 0.25rem 0 !important;
      opacity: 1 !important;
      color: white !important;
    }

    //선택된 시작과 끝 사이 포함된 영역
    &--in-range {
      background-color: rgba(90, 155, 255, 0.7);
      color: white !important;
      width: 2rem;
      margin: 0.1666rem 0;
      border-radius: 0;
      z-index: 5;
      opacity: 0.8;

      &:hover {
        color: white !important;
        font-weight: 700;
        background-color: #5a9bff !important;
      }
    }

    //선택하기 전 시작과 끝 사이에 포함된 영역
    &--in-selecting-range {
      background-color: unset;
      color: white !important;
      border-radius: 0;
    }
  }

  //timeSelect Props 시
  & .react-datepicker__time-container {
    border-left: 1px solid var(--form-border);

    & .react-datepicker__header.react-datepicker__header--time {
      background-color: var(--table-thead-bg);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom: 1px solid var(--form-border);

      & .react-datepicker-time__header {
        margin-top: 0;
        color: $gray-800;
        font-weight: bold;
        font-size: $font-size-base * 1.1;
      }
    }

    & .react-datepicker__time {
      background-color: var(--depth-base);

      & .react-datepicker__time-box {
        & .react-datepicker__time-list {
          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-track {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }

          & .react-datepicker__time-list-item {
            border-bottom: 1px solid var(--form-border);

            &:hover {
              background-color: var(--form-hover-bg) !important;
            }

            &:last-child {
              border-bottom: none;
            }

            &--selected {
              background-color: $default-secondary !important;

              &:hover {
                background-color: $default-secondary !important;
              }
            }

            &--disabled {
              color: $datepicker__muted-color;

              &:hover {
                cursor: default;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  //time Input props 시
  & .react-datepicker__input-time-container {
    width: auto;
    clear: none;
    float: left;
    margin: initial;

    .react-datepicker-time__caption {
      display: none;
    }

    & .react-datepicker-time__input {
      margin: 0;
      border-left: 1px solid var(--form-border);

      & .react-datepicker__header {
        background-color: var(--table-thead-bg);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom: 1px solid var(--form-border);

        & .react-datepicker-time__header {
          margin-top: 0;
          color: $gray-800;
          font-weight: bold;
          font-size: $font-size-base * 1.1;
        }
      }

      & .time-label-box {
        border-top: 1px solid var(--form-border);
        border-bottom: 0;
        margin-top: 0.5rem;

        div:last-child {
          border-left: 1px solid var(--form-border);
        }
      }

      & .list-group {
        height: 180px;

        &:last-child {
          border-left: 1px solid var(--form-border);
        }

        & .list-wrap {
          height: 100%;
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-track {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }

          & .list-group-item {
            text-align: center;
            width: 32px;
            height: 32px;
            padding: 0;
            margin: 0;
            color: var(--base-txt);
            background-color: var(--depth-base);
            border: {
              radius: 0;
              top: 0;
              right: 0;
              bottom: 1px solid var(--form-border);
              left: none;
            }

            &:hover {
              background-color: var(--form-hover-bg) !important;
            }

            &:last-child {
              border-bottom: none;
            }

            &.active {
              font-weight: 700;
              background-color: $default-secondary !important;
              color: #fff !important;
            }

            &.disabled {
              color: var(--depth-9);
            }
          }
        }
      }
    }

    & .time-label-box {
      background-color: var(--form-bg);
    }
  }

  //
  ////모바일 모드
  & .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: rgba(black, 0.5);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }

    & .time-label-box {
      display: flex !important;

      div {
        width: 50%;
        line-height: 2rem;
        text-align: center;
        padding: 0.166rem;
      }
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
      font-size: $font-size-base * 1.8 !important;
    }

    .react-datepicker__header,
    .react-datepicker-year-header {
      font-size: $font-size-base * 1.8 !important;
    }

    .react-datepicker__navigation--previous {
      &:hover {
      }

      &--disabled,
      &--disabled:hover {
        cursor: default;
      }
    }

    .react-datepicker__navigation--next {
      //
      &:hover {
      }

      &--disabled,
      &--disabled:hover {
        cursor: default;
      }
    }
  }
}

%triangle-arrow {
  margin-left: -$datepicker__triangle-size;
  position: absolute;

  &,
  &::before {
    box-sizing: content-box;
    position: absolute;
    border: $datepicker__triangle-size solid transparent;

    height: 0;
    width: 1px;
  }

  &::before {
    content: '';
    z-index: -1;
    border-width: $datepicker__triangle-size;

    left: -$datepicker__triangle-size;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$datepicker__triangle-size;

  &,
  &::before {
    border-top: none;
    border-bottom-color: $datepicker__background-color;
  }

  &::before {
    top: -1px;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$datepicker__triangle-size;

  &,
  &::before {
    border-bottom: none;
    border-top-color: #fff;
  }

  &::before {
    bottom: -1px;
    border-top-color: $datepicker__border-color;
  }
}
