$white: #fff;
$gray-800: #343a40 !default;
$gray-100: #f8f9fa;

#root .app-container.app-theme-pnt-dark {
  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    background-color: #2a2a32 !important;
  }
  .marker-label {
    background-color: #2a2a32 !important;
  }
  .leaflet-container {
    .toast-body {
      & .th {
        background-color: $gray-800;
      }
    }
  }
}

.marker-label {
  padding: 0 0.5rem;
}

.realTime-label {
  & > :first-child {
    font-weight: bold !important;
  }
  & > {
    padding: 0.5rem;
  }
}

.more {
  text-align: right;
  cursor: pointer;
}

.leaflet-container {
  & .detail-popup-open {
    position: absolute;
    z-index: 1000;
    background-color: $white;
    .header {
      width: 25%;
      text-align: center;
    }
  }
  .toast-body {
    & .th {
      background-color: $gray-100;
    }
  }
}

.leaflet-popup-content {
  width: 200px !important;
}
