@import '../pnt/variables';

.btn-white {
  background-color: white;
  border: 1px solid $gray-700;
}

//
.app-theme-pnt-dark,
.app-theme-pnt-plant {
  .custom-action-btn {
    color: white !important;
    background-color: transparent !important;
  }
  .bg-transparent-btn {
    background-color: transparent !important;
  }
}
.app-theme-pnt-light,
.app-theme-pnt-navy {
  .custom-action-btn {
    color: #6748e3 !important;
  }
}

.text-overflow {
  overflow-y: scroll;
}

.search-wrap {
  .pnt-btn.btn-icon {
    min-width: 5.8rem;
  }
}

.transition-card {
  transition: box-shadow 0.2s ease-in-out 0s;
  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.2s ease-in-out 0s;
  }
}

.custom-badge {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  #root .app-container & {
    font-weight: bold;
  }
}

.table-btn {
  height: 27px;
}

.pnt-nameplate {
  .transform-scale-bigger {
    transform: scale(1.6) translateX(1.5px);
  }
}

.react-select__menu,
.select-menu {
  .disabled-select-option {
    cursor: not-allowed !important;

    & > div,
    & > label {
      pointer-events: none;
    }
  }
  .disabled-tree-option {
    .rct-text {
      cursor: not-allowed !important;
      & > label {
        pointer-events: none;
      }
    }
  }

  .disabled-option {
    filter: opacity(0.5);
    .styled-option {
      cursor: not-allowed;
      & > label {
        pointer-events: none;
      }
    }
  }
}
.app-theme-pnt-dark .right-wrapper .right-header {
  background-color: #2c2c30;
}

.app-theme-pnt-plant .right-wrapper .right-header {
  background-color: #344545;
}

.right-wrapper {
  width: 100%;
  height: 16rem;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  margin-right: 2rem;
  overflow: auto;
  .right-header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    background-color: #f2f2f2;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 2rem 1rem;
    border-bottom: 1px solid #cfcfcf;
  }
  .transform-scale-medium {
    transform: scale(1.3);
  }
}

.block-ui.cursor-not-allowed .block-ui-container {
  cursor: not-allowed;
}

// 사이드바 하단
.app-main {
  .pnt-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pnt-sidebar-bottom {
      padding: 1rem 1.5rem 1.5rem;
      .menu-border-box {
        border-top: 1px solid var(--font-black);
      }
    }
    .app-sidebar__inner {
      max-height: calc(100vh - 145px);
    }
  }
}

.closed-sidebar .app-main .pnt-sidebar .metismenu-container .menu-border-box {
  border: none;
}
.closed-sidebar
  .app-main
  .app-sidebar.pnt-sidebar:hover
  .scrollbar-container
  .metismenu-container.visible
  .menu-border-box {
  border-bottom: 1px solid var(--blue);
}
.closed-sidebar .app-main .app-sidebar.pnt-sidebar:hover .pnt-sidebar-bottom .menu-border-box {
  border-top: 1px solid var(--font-black);
}
.closed-sidebar:not(.sidebar-mobile-open) .app-sidebar:hover .scrollbar-container {
  height: calc(100% - 140px);
}
// 사이드바 하단 종료 //

.cursor-default {
  cursor: default;
}

// SH v3 bookmark icon default color
.icon-filter-navy {
  filter: invert(44%) sepia(91%) saturate(5835%) hue-rotate(243deg) brightness(92%) contrast(92%) !important;
}

// SH v3 filter bar 여백
.filter_ver4 {
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 1rem !important;

  & .filter-box:last-child {
    margin-bottom: 0;
  }
}

.widget-util-wrapper {
  $iconSize: 38px;
  display: flex;

  & .util-group {
    display: flex;

    & > * {
      margin-left: 0.5rem;
      &:first-child {
        margin-left: 0;
      }
    }
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    .util-icon {
      width: $iconSize;
      height: $iconSize;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .interval-time-icon {
        transform: scale(1.2) scaleX(-1) !important;
      }
      .interval-time-badge {
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 20px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bolder;
        border-radius: 8px;
      }
    }

    & .dropdown {
      & .dropdown-menu {
        min-width: 7rem;
        border-color: $gray-400;

        &::before,
        &::after {
          display: none;
        }

        .dropdown-item {
          &:hover {
            background-color: $gray-300;
            &:first-child {
              border-radius: 4px 4px 0 0;
            }
            &:last-child {
              border-radius: 0 0 4px 4px;
            }
          }
        }
      }
    }
  }
}

/////////////////////////////////////
/////////// 메뉴 아이콘 설정 ///////////
// 표시아이콘 줄간격 강제 맞춤
.pnt-label--group .label-main.lh-38 {
  line-height: 38px;
  &.label-dot::before {
    top: 50%;
  }
}
// 선택된 아이콘 스타일
.icon-select-box {
  width: 100%;
  border: 1px solid #eceaf2;
  border-radius: 2px;
  padding: 1rem;
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(10%, 28px));
  gap: 20px;
  overflow: auto;
  & .menu-icon-wrapper {
    box-sizing: border-box;
    display: flex;
    padding: 4px;
    border: transparent 1px solid;

    &:hover {
      cursor: pointer;
      background-color: $gray-100;
      border-radius: 2px;
    }

    &.selected,
    &.selected:hover {
      border: $default-brand 1px solid;
      border-radius: 2px;
      filter: none;
      background-color: unset;
    }
  }
}
$menu-icon-filter-dark: invert(49%) sepia(82%) saturate(2656%) hue-rotate(1deg) brightness(105%) contrast(103%);
.icon-select-box {
  .menu-icon-wrapper {
    &.selected {
      border-color: $default-brand;
      .metismenu-icon {
        filter: none;
      }
    }
  }
}
.app-theme-pnt-flower {
  .icon-select-box {
    .menu-icon-wrapper {
      &.selected {
        border-color: #ff9595;
        .metismenu-icon {
          filter: invert(20%) sepia(100%) saturate(3000%) hue-rotate(349deg) brightness(250%) contrast(100%);
        }
      }
    }
  }
}
.app-theme-pnt-dark,
.app-theme-pnt-light,
.app-theme-pnt-navy,
.app-theme-pnt-plant {
  .icon-select-box {
    .menu-icon-wrapper {
      &.selected {
        .metismenu-icon {
          filter: $menu-icon-filter-dark;
        }
      }
    }
  }
}
.app-theme-pnt-dark,
.app-theme-pnt-navy {
  .icon-select-box {
    .menu-icon-wrapper {
      &.selected {
        border-color: #ff8800;
      }
    }
  }
}
.app-theme-pnt-light {
  .icon-select-box {
    .menu-icon-wrapper {
      &.selected {
        border-color: #ff7800;
      }
    }
  }
}
.app-theme-pnt-plant {
  .icon-select-box {
    .menu-icon-wrapper {
      &.selected {
        border-color: #ff961d;
      }
    }
  }
}

#root .app-container.app-theme-pnt-dark {
  @import '../pnt/theme/dark/dark';
  .metismenu-icon {
    filter: invert(100%);
  }
  .menu-icon-wrapper.selected .metismenu-icon {
    filter: $menu-icon-filter-dark;
  }
}
/////////// 메뉴 아이콘 설정 끝 ////////
/////////////////////////////////////

// 툴팁 오버 이벤트 방지
.fade.show {
  .tooltip.show {
    pointer-events: none;
  }
}

// 활력징후 실시간 모니터링 (VitalSignMonitoring) 우상단 checkbox
.btn-group {
  .btn {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .btn-outline-2x + .btn-outline-2x {
    margin-left: -2px;
  }

  .btn-square {
    border-radius: 0;
  }
}

// 활력징후 실시간 모니터링(Partron) Defail, 일간 그래프 하단 nav-tabs
.nav-pills,
.nav-tabs {
  margin-bottom: $spacer;
}

// 알림, 개인설정 등 button focus
.btn {
  &:focus {
    box-shadow: none;
  }
}

// header-dots alarm-box 에서 dropdown 좌측 badge 색
// alert badge warning
.badge-warning {
  color: #212529;
  background-color: #f7b924;
}

// pnt 버튼 (secondary 색상에서 focus) box-shadow 색
// pnt-btn + btn-secondary

.btn-secondary {
  &:focus {
    box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5);
  }
}

// text-area 를 위한 CSS 추가
.pnt-textarea {
  padding: 0.1rem 0.625rem !important;
}

.resize-none {
  resize: none;
}

#root .app-container .clickable:not(.tr) {
  cursor: pointer;
  &:hover,
  &:hover * {
    color: var(--brand);
  }
}
