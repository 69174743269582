// close 버튼
.close {
  float: right;
  font-size: $close-font-size;
  font-weight: $close-font-weight;
  line-height: 1;
  color: $close-color;
  text-shadow: $close-text-shadow;
  opacity: 0.5;
  &:hover {
    opacity: 0.75;
  }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

// stylelint-disable-next-line selector-no-qualifying-type
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

// Future-proof disabling of clicks on `<a>` elements

// stylelint-disable-next-line selector-no-qualifying-type
a.close.disabled {
  pointer-events: none;
}

// Modals
.modal-header,
.modal-footer {
  background: $gray-100;
}

.modal-footer {
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.modal-dialog {
  box-shadow: $box-shadow-lg-default;
  border-radius: $border-radius;
}

// Alert Multiple Modal
.critical-backdrop {
  @keyframes pulse {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.2;
    }
  }
  // danger color
  background-color: #f14665;
  opacity: 0.2;
  animation: pulse 2s infinite;
}

/**
 Modal border
 */

//// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  color: $modal-content-color;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  border-radius: $modal-content-border-radius;
  //@include box-shadow($modal-content-box-shadow-xs);
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  align-items: flex-start; // so the close btn always stays on the upper right corner
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  padding: $modal-header-padding;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;
  border-top-left-radius: $modal-content-border-radius;
  border-top-right-radius: $modal-content-border-radius;

  .close {
    padding: $modal-header-padding;
    // auto on the left force icon to the right even when there is no .modal-title
    margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
  }
}
