@import '../variables';
@import '../theme/default/default';

.item_count {
  padding: 0.25rem 0.4rem !important;
  min-width: 24px;
  text-align: center;
}

#root .app-container.app-theme-pnt-dark {
  @import '../theme/dark/dark';
  .item_count {
    border: 1px solid $depth-base !important;
  }
}

.item_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
  padding: 0 0.3rem 0 0.1rem;
  & > span {
    transform: scale(0.8) !important;
    vertical-align: center;
  }
}
