.content-wrapper {
  height: fit-content;
  min-height: 100%;
  display: grid;
  grid-template-rows: min-content auto;
  row-gap: 0.5rem;
  &.details {
    grid-template-rows: unset;
    grid-template-columns: 1fr 3fr;
    column-gap: 1rem;
    :global(.label-main) {
      min-width: 7rem;
    }
    .vitalsings-area {
      display: grid;
      grid-template-rows: auto;
      row-gap: 2rem;

      .chart-container {
        display: grid;
        grid-auto-rows: min-content;
        row-gap: 1rem;

        .line-chart-area {
          height: 300px;
          :global(.no-data) {
            border: 1px solid #eceaf2;
          }
        }
      }
    }
  }
  .action-area {
    display: flex;
    justify-content: end;
  }
  .table-wrapper {
    overflow-x: auto;
  }
}

.table-input {
  min-width: unset;
  width: 100%;
  height: 100%;

  input {
    border: none !important;
    color: inherit !important;
  }

  & input:focus {
    outline: 1px #c6c6ff solid !important;
    border: 1px solid #c6c6ff !important;
  }
}

.flex-2 {
  flex: 2 !important;
}

.table-input:global(.pnt-input--group:not(.form-disable)) input:global(.pnt-input) {
  background-color: transparent !important;
}

.table-input:global(.form-disable.pnt-input--group) input:global(.pnt-input) {
  filter: grayscale(1);
}

:global(.pnt-table) {
  .disabled {
    filter: grayscale(1);
  }
}

.disabled-dropdown-item,
.disabled-dropdown-item:hover,
.disabled-dropdown-item:focus {
  opacity: 0.6;
  background-color: var(--depth-10);
}
