$color-list: yellowgreen, blue, gold, sienna, red;

@each $color in $color-list {
  .pulse-#{$color} {
    animation: pulse-#{$color} 1.5s infinite;
    opacity: 0.6;
  }

  @keyframes pulse-#{$color} {
    0% {
      stroke-width: 0;
      stroke: rgba($color, 0.8);
    }
    100% {
      stroke-width: 100;
      stroke: rgba($color, 0);
    }
  }
}

.big {
  font-size: 10rem !important;
}
