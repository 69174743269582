@media screen and (min-width: $breakpoint-labtop) {
  .play-body-custom {
    background-color: transparent;
    border-radius: 0.25rem;
    padding: 0.5% 2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .body-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    & .row-custom:nth-child(1) {
      justify-content: center;
      margin-bottom: 0 !important;

      & .tracing-play-bar {
        border: none;
        border-radius: 100px;
        height: 0.3rem;
        width: 100%;
        margin: 1rem 0;
        box-shadow: none;
        padding: 0;

        & .noUi-origin {
          margin-right: 0.5rem;

          & .noUi-handle {
            box-shadow: none;
            background-color: #6748e3;
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
        }
      }
    }

    & .row-custom:nth-child(2) {
      justify-content: center;
      padding: 0;

      & > button {
        margin-right: 1rem;
        font-weight: normal;

        &:nth-child(2) {
          max-height: 2rem;
          min-width: 2rem;

          & svg {
            transform: scale(0.8) translateX(10%);
          }
        }
      }

      & > div {
        max-height: 2rem;
        max-width: 5rem;
      }

      & span.play-time {
        line-height: 2rem;
        margin-left: 1rem;
        color: white;
        font-size: 0.8rem;
      }
    }

    .play-button {
      width: 3rem;
      height: 3rem;

      & > .material-icons-round {
        font-size: 2.5rem;
      }
    }

    .show-all-button {
      margin-right: 0.5rem;
    }
  }
}

@media screen and (min-width: 0) {
  .play-body-custom {
    //background-color: #1d2333;
    border-radius: 0.25rem;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .body-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 1rem;
    }
    & .row-custom:nth-child(1) {
      justify-content: center;
      margin-bottom: 0 !important;
      margin: 0 auto;
      width: 98%;

      & .tracing-play-bar {
        border: none;
        border-radius: 100px;
        background-color: #424764;
        height: 0.3rem;
        width: 100%;
        margin: 1rem 0;
        box-shadow: none;

        & .noUi-base {
          & .noUi-origin {
            margin-right: 0.5rem;

            & .noUi-handle {
              box-shadow: none;
              background-color: #6748e3;
              width: 1rem;
              height: 1rem;
              border-radius: 100px;
              &::before {
                display: none;
              }
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }

    & .row-custom:nth-child(2) {
      justify-content: space-between;
      margin-bottom: 0 !important;
      margin: 0 auto;
      width: 98%;

      & > button {
        margin-right: 0.5rem;
        font-weight: normal;

        &:nth-child(1) {
          max-height: 2rem;
          max-width: 7rem;
          line-height: 2rem;
        }
        &:nth-child(2) {
          max-height: 2rem;
          min-width: 2rem;

          & svg {
            transform: scale(0.8) translateX(10%);
          }
        }
      }

      & > div {
        max-height: 2rem;
        max-width: 4rem;
      }

      & span.play-time {
        line-height: 2rem;
        margin-left: 0.5rem;
        color: white;
        font-family: NotoSans;
        font-size: 0.5rem;
      }
    }
    .play-button {
      width: 3rem;
      height: 3rem;

      & > .material-icons-round {
        font-size: 2.5rem;
      }
    }
  }
}

.control-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.select-color__mode_dark {
  background-color: #4c4c52 !important;
  color: black;
}

.select-color__mode_navy {
  background-color: #d0cfd8 !important;
  color: black;
}
