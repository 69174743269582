// input_group 관련 css
// Input groups

.input-group {
  .input-group-prepend {
    div:not([class]) .react-datepicker__input-container,
    & + div .react-datepicker__input-container {
      .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: $input-border-radius;
        border-bottom-right-radius: $input-border-radius;
      }
    }
  }

  & > div:not([class]) {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    & .react-datepicker__input-container {
      .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.input-group {
  .input-group-prepend {
    & + div {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
    }
  }

  & > .react-datepicker-wrapper {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    & > .react-datepicker__input-container > .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

// Forms Legend

legend {
  font-size: $font-size-base;
  font-weight: bold;
}

// Headings

.form-heading {
  font-size: $font-size-lg;
  margin: 0;
  color: $primary;

  p {
    color: $gray-600;
    padding: 0.3rem 0 0;
    font-size: $font-size-base;
  }
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input-group-prepend,
.input-group-append {
  display: flex;

  // Ensure buttons are always above inputs for more visually pleasing borders.
  // This isn't needed for `.input-group-text` since it shares the same border-color
  // as our inputs.
  .btn {
    position: relative;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }

  .btn + .btn,
  .btn + .input-group-text,
  .input-group-text + .input-group-text,
  .input-group-text + .btn {
    margin-left: -$input-border-width;
  }
}

.input-group-prepend {
  margin-right: -$input-border-width;
}
.input-group-append {
  margin-left: -$input-border-width;
}
