@import '../variables';

.room_container {
  display: grid;
  gap: 5px;
  grid-gap: 5px;
  grid-template-rows: min-content max-content;
}

.ward_container {
  min-height: 28px;
}

.detail_info_table {
  height: 4.25rem;
}

.detail_graph {
  height: 300px !important;
}
